<template>
  <div class="page" id="indexList">
    <el-form size="small" class="query-form searchForm" ref="inputForm" :model="inputForm"
             @keyup.enter.native="getDataList(1,1)"
             label-width="80px">
      <el-form-item label="关键字" prop="resourceName">
        <el-input v-model.trim="inputForm.resourceName"
                  placeholder="请输入资源名称/文件名称(限50字)"
                  maxlength="50" clearable></el-input>
      </el-form-item>
<!--      <el-form-item label="成文时间" prop="accidentTime" class="datTime">-->
<!--        <el-date-picker-->
<!--            v-model="inputForm.accidentTime"-->
<!--            type="daterange"-->
<!--            style="width: 100%;"-->
<!--            value-format="yyyy-MM-dd"-->
<!--            range-separator="至"-->
<!--            start-placeholder="开始日期"-->
<!--            end-placeholder="结束日期">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
      <span style="margin-left: 15px">
        <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
      </span>
      <el-button class="f_r" type="primary" size="small"
                 v-if="pageType != 'general'"
                 v-show="hasPermissionButton(`dyyg:comprehensive:indexList:add`)"
                 icon='el-icon-plus' @click="addData(0)">新增
      </el-button>
    </el-form>
    <div class="bg-white">
      <div class="text_right">
        <el-popover v-if="pageType != 'general'"
                    placement="bottom-start"
                    width="200" style="margin-right: 15px"
                    trigger="hover"
                    content="支持资源或文件/文件夹对应的资源批量公开，公开后的资源支持在【综合资源】中查看。">
          <el-button slot="reference" icon="el-icon-open"
                     v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchOpen`)"
                     size="small" @click="bulkOperation('',1)">
            批量公开
          </el-button>
        </el-popover>
        <el-popover v-if="pageType != 'general'"
                    placement="bottom-start"
                    width="200" style="margin-right: 15px"
                    trigger="hover"
                    content="支持资源或文件/文件夹对应的资源批量隐藏，隐藏后的资源只在【我的综合资源】中，仅自己可见。">
          <el-button slot="reference" icon="el-icon-turn-off"
                     v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchOff`)"
                     size="small" @click="bulkOperation('',2)">
            批量隐藏
          </el-button>
        </el-popover>
        <el-button v-if="pageType != 'general'" icon="el-icon-delete"
                   v-show="hasPermissionButton(`dyyg:comprehensive:indexList:batchDelete`)"
                   type="danger" size="small" @click="bulkOperation('',0)">
          批量删除
        </el-button>
      </div>
      <el-table
          v-if="showList"
          :data="dataList"
          size="small"
          v-loading="loading"
          ref="multipleTable"
          :height="pageType == 'general' ? 'calc(100vh - 295px)' : 'calc(100vh - 325px)'"
          class="table"
          :row-key="'id'"
          :header-cell-style="{background:'#F9DFDF'}"
          @selection-change="selectionChangeHandle"
      >
        <el-table-column :reserve-selection="true" type="selection" width="50"/>
        <el-table-column prop="resourceName" label="资源名称" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-html="scope.row.resourceName"></div>
          </template>
        </el-table-column>
        <el-table-column prop="fileName" label="文件名" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="flex_l_c">
              <el-image v-if="scope.row.folderFlag == 0" fit="contain" :src="require('@/assets/img/folder.png')"
                        class="itemImg"></el-image>
              <el-image v-else fit="contain" :src="scope.row.url" class="itemImg">
                <el-image slot="placeholder" fit="cover" class="itemImg"
                          :src="getPlaceholderImg(scope.row.type)"></el-image>
                <el-image slot="error" fit="cover" class="itemImg"
                          :src="getPlaceholderImg(scope.row.type)"></el-image>
              </el-image>
              <div v-html="scope.row.fileName" style="padding-left: 5px;width: 100px;" class="omit"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" sortable label="修改时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="owningFolder" sortable label="所属文件夹" show-overflow-tooltip></el-table-column>
        <el-table-column prop="typeName" label="资源类型" show-overflow-tooltip></el-table-column>
        <el-table-column prop="fileSizeFormat" sortable label="大小" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.fileSizeFormat ? scope.row.fileSizeFormat : '0 B' }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" type="text"
                       @click="fileDetail(1, scope.row)"
                       v-show="hasPermissionButton(`dyyg:comprehensive:indexList:detail`)">
              详情
            </el-button>
            <!--            <el-button v-if="pageType != 'general'" style="margin-bottom: 10px" size="mini" type="text"-->
            <!--                       @click="fileDetail(2, scope.row)"-->
            <!--                       v-show="hasPermissionButton(`dyyg:comprehensive:indexList:edit`)">-->
            <!--              修改-->
            <!--            </el-button>-->
            <el-button v-if="pageType != 'general'" style="margin-bottom: 10px" size="mini" type="text"
                       @click="bulkOperation(scope.row,0)"
                       v-show="hasPermissionButton(`dyyg:comprehensive:indexList:delete`)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text_center">
        <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="size"
            :total="total"
            background
            layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <add-comprehensive ref="addComprehensive" @refreshList="getDataList(1,1)"></add-comprehensive>
    <file-details ref="fileDetails" @refreshList="getDataList()"></file-details>
  </div>
</template>

<script>
import AddComprehensive from "@/views/modules/dyyg/comprehensive/addComprehensive.vue";
import FileDetails from "@/views/modules/dyyg/comprehensive/fileDetails.vue";

export default {
  name: "searchResult",
  components: {AddComprehensive, FileDetails},
  data() {
    return {
      showList: false,
      pageType: '',
      inputForm: {
        resourceName: '',
        accidentTime: [],
        startTime: '',
        endTime: '',
      },
      dataList: [],
      loading: false,
      dataListSelect: [],

      current: 1,
      size: 10,
      total: 0,
    }
  },

  mounted() {
    this.pageType = this.$route.query.pageType
    Object.keys(this.inputForm).forEach((key) => {
      this.inputForm[key] = this.$route.query.inputForm[key]
    })
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
    this.showList = true
    this.$nextTick(() => {
      this.getDataList(1, 1)
    })
  },

  methods: {
    getDataList(type, dividePage) {
      if (type == 1) {
        this.current = 1
      }
      if (dividePage) {
        this.$refs.multipleTable.clearSelection()
      }
      this.loading = true
      if (this.inputForm.accidentTime && this.inputForm.accidentTime.length != 0) {
        this.inputForm.startTime = this.inputForm.accidentTime[0]
        this.inputForm.endTime = this.inputForm.accidentTime[1]
      } else {
        this.inputForm.startTime = ''
        this.inputForm.endTime = ''
        this.inputForm.accidentTime = []
      }
      let searchRecord = {
        ...this.inputForm,
        openFlag: this.pageType != 'general' ? '' : 0,
        current: this.current,
        size: this.size,
        type: '',
      }
      let fechUrl = this.api.digital.resourceSearchByType
      this.$axios(fechUrl, searchRecord, 'post').then((res) => {
        if (res.status) {
          this.dataList = res.data.records
          this.dataList.forEach(item => {
            this.$delete(item, 'children')
          })
          this.total = parseInt(res.data.total)
          this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
          })
          if (this.dataList.length == 0 && this.current > 1) {
            this.current--;
            this.getDataList()
          }
        } else {
          this.$message.error('查询失败');
        }
        this.loading = false
      })
    },

    //资源新增
    addData(num) {
      this.$refs.addComprehensive.init()
    },

    resetting() {
      this.$refs.inputForm.resetFields();
      this.getDataList(1, 1)
    },

    //文件 1详情 2修改
    fileDetail(num, row) {
      this.$refs.fileDetails.init(row, this.pageType)
    },

    //批量删除
    bulkOperation(row, num) {
      let ids
      let list = row ? [row] : this.dataListSelect
      if (list.length == 0) {
        this.$message.warning('请至少选择一条数据')
        return
      }
      //删除
      if (num == 0){
        ids = list.map(item => {
          return item.id
        })
        this.$confirm(`您是否确认删除该数据?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios(this.api.digital.comprehensiveResourcesFileLinkRemoveByIds, ids, 'post').then(data => {
            if (data && data.status) {
              this.$message.success(`删除成功`)
              this.getDataList('', 1)
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }else {
        //公开和隐藏
        ids = list.map(item => {
          return item.resourceId
        })
        this.$confirm(`您是否确认${num == 1 ? '公开' : '隐藏'}该数据?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$axios(this.api.digital.updateResourcesByTypeAndIds, {
            ids,
            type: num,
          }, 'post').then(data => {
            if (data && data.status) {
              this.$message.success(`${num == 1 ? '公开' : '隐藏'}成功`)
              this.getDataList('', 1)
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    // 展览每页数
    sizeChangeHandle(val) {
      this.size = val;
      this.getDataList(1, 1)
    },
    // 展览当前页
    currentChangeHandle(val) {
      this.current = val;
      this.getDataList('', 1);
    },

    // 获取占位图
    getPlaceholderImg(resourceType) {
      switch (resourceType) {
        case 0:
          return require('@/assets/img/collThree.png')
        case 1:
          return require('@/assets/img/collPhoto.png')
        case 2:
          return require('@/assets/img/collAudio.png')
        case 3:
          return require('@/assets/img/collVideo.png')
        default:
          return require('@/assets/img/collFile.png')
      }
    },
  }
}
</script>

<style scoped>
.itemImg {
  height: 50px;
  width: 50px;
}

.searchForm {
  padding-bottom: 15px !important;

  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 28%;
    margin-bottom: 0 !important;
  }

  .datTime.el-form-item--small.el-form-item {
    display: inline-block;
    width: 35%;
  }
}
</style>